/* FOR Tablet Portrait */
@media (max-width:991px) and (min-width:768px){
	/*html,
	body,
	#root {
		height: auto; //  !important
		// OPTION: FOR "bounce" effects or refreshes
		// https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
		overscroll-behavior: none; // contain
	}*/

	.lockScroll {
		overflow: hidden !important;
	}

	.modal-full-sm .modal-dialog {
		max-width: 95%;
	}
	
	// FileUpload - antd  
  .fs-file-upload .ant-upload-list {
    width: 250px;
  }

	// Stimulsoft
  .FSALogo {
		position: static !important;
	}
}
/* END FOR Tablet Portrait */