// 
a:not([disabled]),
select:not([disabled]), 
.dropdown-item:not(.disabled):not(:disabled), 
.nav-link:not(.disabled),
// [role="button"]:not(.disabled),
.list-group-item-action:not(.disabled),

::-webkit-file-upload-button,
.custom-file-input, 
.custom-file-label {
	cursor: pointer;
}

// :not(.SplitPane)
// :disabled,
*:not(button).disabled {
  pointer-events: none;
  user-select: none;
}

.nav-link {
  color: $dark;
}

.link-sm .nav-link {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

button.nav-link {
  border: 0;
}
button.nav-link:not(:hover) {
  background-color: transparent;
}

// .btn-light,
// .btn-light:hover,
// .btn-light.disabled,
// .btn-light:disabled {
// 	border-color: #ccc;
// }

// .btn-group .btn-secondary:not(:hover) {
//   border-color: #aaa;
// }

// Active .btn in btn-group / from parent
// .open > .btn {
//   color: #fff;
//   background-color: #343a40;
//   border-color: #343a40;
// }

// == Custom button ==
.btn-flat {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}
// .btn-flat.active,
.dropdown.show > .btn-flat, // <- dropdown menu open
.btn-flat.ant-popover-open,
// .btn-flat:focus,
.btn-flat:not([disabled]):hover {
  background-color: #e2e6ea;
}

.btn-flat:not([disabled]):active {
  background-color: #cbcbcc;// #d3d9df !important
}

a.disabled {
  pointer-events: none;
}
.btn[disabled] {
  cursor: not-allowed;
}

label {
  font-family: inherit !important;
}

label.btn,
label.dropdown-item {
	margin-bottom: 0;
}

.custom-switch,
.custom-switch label {
  // display: inline-block;
  user-select: none;
  cursor: pointer;
}

// .custom-switch {
//   display: inline-flex;
//   align-items: center;
// }

// OPTION
// === textarea ===
textarea {
  min-height: calc(1.5em + 1.3rem + 2px);// calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.65rem + 2px);// calc(1.5em + 1rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.35em + 1.1rem + 2px);// calc(1.5em + .5rem + 2px);
}

label.form-group {
  display: block;
}

// Button
// CUSTOM btn size
.btn-xs, 
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

// .navbar-toggler:focus {
//   outline: none;
// }
// a.navbar-toggler span {
// 	pointer-events: none;
// }

// .i.badge {
//   display: inline-block;
// }

// == dropdown-menu ==
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-item {
  padding: .25rem 1rem;
}

.nav-link:hover:not(:active):not(.active),
.dropdown-item:not(:active):not(.active):hover {
  background-color: $hover-color;
}

.navbar .nav-link:hover {
  background-color: inherit;
}

.nav-link:active {
  background-color: $primary !important;
  color: #fff !important;
}

.nav-link:focus {
  box-shadow: var(--box-shadow-1); // $btn-focus-box-shadow
}

.nav-link:active .text-muted, 
.nav-link.active .text-muted {
  color: #fff !important;
}

.dropdown-menu > hr {
  margin: 4px 0;
}

// == form-control ==
.form-control.fal:before {
  color: #777;// #ccc | $text-muted
  // margin-right: .5rem;
}

// .form-check,
// .custom-control,
.nav-link {
  user-select: none;
}
// DEV OPTION - For checkbox custom in dropdown-item
.custom-control-label {
  display: inline-block;
}
label.dropdown-item .custom-control-label {
  pointer-events: none;
}
// == END form-control ==

// OPTION: For Performance & Accessibility
// https://developer.mozilla.org/en-US/docs/Web/CSS/position
/*.position-sticky {
  will-change: transform;
}*/

// === modal ===
// Disabled transition when modal open / transform animate in windows 10
.modal.show.fade .modal-dialog {
  transform: none !important;
}

.modal-full .modal-dialog {
  max-width: 100%;
  // margin-top: 0;
  // margin-bottom: 0;
  margin: 0;
}

.modal-full .modal-content {
  border: 0;
  border-radius: 0;
}

.modal-full-2 .modal-dialog {
  max-width: calc(100% - 12px);
  margin-top: 6px;
  margin-bottom: 6px;
}
.modal-full-2 + .modal-backdrop {
//   background-color: #999;
//   opacity: 1;
  opacity: .75;
}

.modal-full-2 .modal-content {
  height: calc(100vh - 12px);// min-height
}

// .modal-down.modal {
//   display: flex !important;
//   overflow: hidden;
// }
// .modal-down .modal-dialog {
//   margin-top: 0;
//   margin-bottom: 0;
//   align-self: flex-end;
//   width: inherit;
// }
// .modal-down.fade:not(.show) .modal-dialog {
//   transform: translateY(50px);
// }
// .modal-down .modal-content {
//   border-bottom: 0;
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
// }
// === END modal ===

// == custom-control ==
label.custom-control {
  margin-bottom: 0;
}

// == badge ==
// .badge.q {
//   display: inline-block;
// }
// .badge.cpoin {
//   user-select: none;
// }

// == form-control / custom-select ==
.form-control:not(textarea):not(:focus):not([disabled]):not(.nav-link) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// OPTION
// .form-control:disabled,
// .custom-select:disabled {
//   cursor: not-allowed;
// }

// Disabled clickable & editable elements
// fieldset:disabled, 
fieldset:disabled .fieldset-child, 
fieldset:disabled [draggable="true"], 
fieldset:disabled [contenteditable="true"], 
fieldset:disabled [tabindex], 
fieldset:disabled button, 
fieldset:disabled input, 
fieldset:disabled a {
  pointer-events: none;
  user-select: none;
}
// antd Switch
fieldset:disabled .ant-switch {
  opacity: .4;
}

*:focus {
  // outline: none !important;// Bugs overflow : https://www.smashingmagazine.com/2021/04/css-overflow-issues/
  outline-color: transparent;
}

// input-group
/*.input-append:focus + .input-group-append > .input-group-text {
  border-color: #69b3ff;
}*/

// Show / Hide dropdown toggle arrow
.dd-toggle-1:not(:hover)::after {
  // margin-left: auto !important;
  opacity: 0;
}

.show > .dd-toggle-1::after {
  opacity: 1;
}

.form-control-disabled {
  background: #F3F6F9;
}

// Form Editor:
.nav-draggable-link:not(:active):not(.active) {
  &:hover {
    background: $white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .08);
  }
}

.ant-collapse-ghost {
  > .ant-collapse-item.draggable-collapse-container {
    > .ant-collapse-header {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.8;
      padding-left: 52px;

      .ant-collapse-arrow {
        left: 24px;
      }
    }
    > .ant-collapse-content {
      > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}
// END Form Editor: