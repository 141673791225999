﻿//
// antd:
// OVERRIDE metronic:
.anticon svg {
  vertical-align: initial;
}

.ant-btn {
  border-radius: 0.42rem;
}

// <Skeleton />
.ant-skeleton.rounded-0 .ant-skeleton-title,
.ant-skeleton.rounded-0 ul > li {
  border-radius: 0 !important
}

// Antd: <Skeleton />
// .ant-skeleton{
//  cursor: wait;
// }

/*.fs-skeleton-grid .ant-skeleton-paragraph {
	display: flex;
}

.fs-skeleton-grid .ant-skeleton-paragraph > * + * {
	margin-top: 0 !important;
	margin-left: 15px;
}*/
// END Antd: <Skeleton />

// Select:
.ant-select > .ant-select-selector:not(.ant-select-focused) {
  border-color: #E4E6EF !important;
}

.ant-select > .ant-select-selector {
  border-radius: 0.28rem !important;
}

// Table size:
.fsys-table .ant-table-small .ant-table-tbody {
  font-size: .95rem;
}
// .ant-table.ant-table-small .ant-table-thead > tr > th
.fsys-table .ant-table-small .ant-table-thead > tr > th {
  padding-top: 10px;
  padding-bottom: 10px;
  // background-color: #EEF0F8;
}

.fsys-table .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: 0;
}

.fsys-table .ant-table-filter-trigger {
  margin-left: auto;
}

.fsys-table .ant-table-filter-trigger .anticon {
  position: static;
  transform: none;
}

.fsys-table .ant-table-pagination {
  padding-right: .75rem;
  padding-left: .75rem;
}

.ant-table-filter-trigger.active:not(:hover) {
  background-color: $hover-color; // $info | $success
  box-shadow: inset 0 0 0 1px rgba(38,143,255,.5);
}

// Transfer:
// Set equal width (ellipsis items)
/*.fsys-transfer.equal-width .ant-transfer-list {
  width: 100%;// auto
  flex: auto;
  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}*/

// Loading:
// .transfer-loading .ant-transfer-list
// .transfer-loading .ant-transfer-list-body-not-found {
//   flex: auto;
//   position: relative;
//   cursor: progress;
// }
// .transfer-loading .ant-empty {
//   display: none;
// }
// // .transfer-loading .ant-transfer-list::after
// .transfer-loading .ant-transfer-list-body-not-found::after {
//   content: "";// LOADING
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
//   // background-color: rgba(255,255,255,.3);
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
//   z-index: 1;

//   display: inline-block;
//   width: 2rem;
//   height: 2rem;
//   // vertical-align: text-bottom;
//   border: .25em solid currentColor;
//   border-right-color: transparent;
//   border-radius: 50%;
//   color: #007bff;
//   -webkit-animation: spinner-border .75s linear infinite;
//   animation: spinner-border .75s linear infinite;

//   // cursor: progress;
// }

.ant-transfer-list {
  background-color: #fff;
}

.ant-transfer-list-body-search-wrapper {
  border-bottom: 1px solid #f0f0f0;
}

// antd-img-crop
.antd-img-crop-modal .ant-modal-body {
	padding: 0 0 16px;
}
// END antd-img-crop

// <Tree />
.rc-tree-child-tree {
  display: block;
}
.node-motion {
  transition: all .3s;
  overflow-y: hidden;
}

// <Select />
.ant-select.is-invalid {
  box-shadow: 0 0 0 0.05rem var(--danger); // rgb(225, 83, 97)
  border-radius: 0.28rem;
}

// DatePicker, InputNumber, 
.ant-input-number.is-invalid, 
.ant-picker.is-invalid {
  border-color: #F64E60;
}

.ant-input-number-input{
  &:focus{
    border: 2px solid #3699ff !important;
  }
}
.ant-picker-focused{
  border: 2px solid #3699ff !important;
}
.ant-switch{
  &:focus{
    box-shadow: 0 0 0 2px rgba(54, 153, 255, 1);
  }
}
.ant-select-focused{
  box-shadow: 0 0 0 2px rgba(54, 153, 255, 1);
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus{
  border: 2px solid #3699ff !important;
}
// .ant-select {
//   border-radius: 0.28rem;
// }

.ant-picker.is-valid, 
.ant-input-number.is-valid {
  border-color: var(--success);
}

// <Radio />
.ant-radio-wrapper {
  display: inline-flex;
  align-items: center;
}

// Upload = <FileUpload />
.fs-file-upload .ant-upload-list:empty:after {
  // content: "No files";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: "\f31a";
  font-size: 3em;
  text-align: center;
  width: 100%;
  line-height: 60vh;// OPTION
}

.fs-file-upload > span {
  display: flex;
  // flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
}

.fs-file-upload .fs-antd-upload {
  height: auto;
  // display: flex;
  flex-grow: 1;
  margin: 9px;
}

.fs-file-upload .ant-upload-list {
  width: 300px;
  flex: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  max-height: calc(100vh - 15rem);
}

// .fs-file-upload.multiple span a
.fs-file-upload span a {
  cursor: auto;
}

// .fs-file-upload 
.ant-upload-list-picture .ant-upload-list-item-progress {
  bottom: 7px;
}

.fs-file-upload:not(.multi-file) {
  .ant-upload-list {
    display: none;
  }
}

// <Select /> <Tag />
.fs-select-tag .ant-select-selection-item {
  background: #c3f5ff;// #e6f7ff
}

// .fs-antdPop
// .ant-picker 
// .ant-picker.ant-picker-focused {
//   z-index: 1051;
// }

// // :not(.ant-picker-dropdown-hidden)
// .ant-picker-dropdown:after {
//   content: "";
//   position: fixed;
//   display: block;
//   inset: 0;
//   z-index: 1049;
// }

// .ant-upload-list-picture .ant-upload-list-item-thumbnail img, 
// .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {

// }

.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  object-fit: cover;
}

.fs-pop-filethumb .ant-popover-arrow {
  display: none;
}

// .fs-pop-filethumb .ant-popover-title, 
.fs-pop-filethumb .ant-popover-inner-content {
  padding: 0;
}

.fs-pop-filethumb .grid-64 {
  max-width: 22rem; // 21.5rem
  // max-height: 50vh;
}

// Tab without margin-bottom
.fs-antd-tab.mb-0 .ant-tabs-nav {
  margin-bottom: 0;
}

// NumberInput
.ant-input-number.text-right {
  .ant-input-number-input:not(.text-left) {
    text-align: right;
  }

  &:hover:not(.ant-input-number-disabled) {
    .ant-input-number-input:not(.text-left) {
      text-align: left;
    }
  }
}
// END NumberInput

// Custom Now button
// .ant-picker-datetime-panel + .ant-picker-footer {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

// Loading Card Form Editor Module
.w-100 > .ant-skeleton-avatar {
  width: 100%;
}

.min-h-160px > .ant-skeleton-avatar {
  height: 160px;
}

// skeleton-card
.rounded > .ant-skeleton-avatar {
  border-radius: 0.42rem;
}
// END Loading Card Form Editor Module

// Transfer Reverse
.fs-transfer-reverse .ant-transfer-operation .anticon > svg {
  transform: rotate(180deg);
}

.searchable-select .fs-r-select__control--menu-is-open .fs-r-select__single-value {
  opacity: 0;
}

.select-with-img .ant-select-selection-item .img-frame {
  display: none;
}