﻿// <ScrollTo />
.q-scrollto {
  position: relative;
}

// .icon-picker {
//   height: 364px;
//   flex-direction: column;
//   align-items: stretch;
// }

// .icon-picker.show {
//   display: flex;
// }

.icon-picker .dropdown-item {
  display: inline-flex;
}
// OPTION
// .icon-picker .dropdown-item:not(:hover){
//   background-color: #fff;
// }

.ip-btn-copy {
  transform-origin: bottom;
  animation-fill-mode: forwards;
  transform: scaleY(0);
  height: 0;
  opacity: 0;
  transition: transform ease-in-out .2s;
}

.icon-view:hover .ip-btn-copy {
  // position: relative;
  transform: none;
  height: auto;
  opacity: 1;
}

.icoNotFound {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 4; // 2.5
}
.icoNotFound:after {
  content: attr(data-notfound);// aria-label
}

.icoNotFound > * {
  display: none !important;
}

// {
//   max-height: 226px !important;
// }

// With svg / img
.icon-item > img:not([width]), 
.icon-item > svg {
  display: inline-block;
  width: 30px; // 28px
  height: 30px;
}

