// 
// .fi:before {
//   font-family: 'Font Awesome 5 Pro';
//   font-weight: 300;
// }

.fi-htm:before, 
.fi-html:before, 
.fi-css:before, 
.fi-js:before,
.fi-scss:before, 
.fi-sass:before,
.fi-less:before,
.fi-md:before, 
.fi-markdown:before {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fi-htm:before, 
.fi-html:before {
  content: "\f13b";
}

.fi-css:before {
  content: "\f38b";
}

.fi-js:before {
  content: "\f3b8";
}

.fi-json:before {
  content: "\f7ea";
}

.fi-svg:before {
  content: "\f1c9";
}

.fi-scss:before, 
.fi-sass:before {
  content: "\f41e";
}

.fi-less:before {
  content: "\f41d";
}

.fi-msg:before,
.fi-txt:before {
  content: "\f15c";
}

.fi-md:before, 
.fi-markdown:before {
  content: "\f60f";
}

.fi-doc:before, 
.fi-docx:before {
  content: "\f1c2";
}

.fi-xls:before, 
.fi-xlsm:before, 
.fi-xlsx:before {
  content: "\f1c3";
}

.fi-csv:before {
  content: "\f6dd";
}

.fi-pdf:before {
  content: "\f1c1";
}

.fi-pptx:before, 
.fi-ppt:before {
  content: "\f1c4";
}

.fi-zip:before, 
.fi-7z:before, 
.fi-rar:before, 
.fi-tar:before {
  content: "\f1c6";
}

.fi-webm:before, 
.fi-mkv:before, 
.fi-mov:before, 
.fi-flv:before, 
.fi-avi:before, 
.fi-wmv:before, 
.fi-3gp:before, 
.fi-mp4:before {
  content: "\f1c8";
}

.fi-flac:before, 
.fi-aac:before, 
.fi-m4a:before, 
.fi-wav:before, 
.fi-mp3:before {
  content: "\f1c7";
}


