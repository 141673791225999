$navicon-font-family: "navigation-icon" !default;
$navicon-font-path: "fonts" !default;

$navicon-Asset-Management: "\e900";
$navicon-Audit--External: "\e901";
$navicon-Audit--Internal: "\e902";
$navicon-Budgeting-and-Forecasting: "\e903";
$navicon-CRM: "\e904";
$navicon-Dashboard: "\e905";
$navicon-Document-Management: "\e906";
$navicon-Facilities: "\e907";
$navicon-Favourites: "\e908";
$navicon-HR: "\e912";
$navicon-Inventory: "\e913";
$navicon-IT: "\e914";
$navicon-Legal: "\e915";
$navicon-Management-Accounting: "\e916";
$navicon-Miscellaneous: "\e917";
$navicon-MTD: "\e918";
$navicon-Operations: "\e919";
$navicon-Period-End: "\e91a";
$navicon-Portals: "\e909";
$navicon-Professional-Services: "\e90a";
$navicon-Project-Management: "\e90b";
$navicon-Purchasing: "\e90c";
$navicon-Reconciliation-Management: "\e90d";
$navicon-Reports: "\e90e";
$navicon-Research-and-Development: "\e90f";
$navicon-Sales: "\e910";
$navicon-Statutory-Accounting: "\e911";

