// Variables
$main-color : #00A1D6 !default; // #584475;
$hover-color : #e6f3ff !default;// #f1e7ff

:root {
  --main-color: #{$main-color};
  --hover-color: #{$hover-color};
  --shadow-blue: 0 0 0 .2rem rgba(38,143,255,.5);
  --box-shadow-1: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  --text-color: #{$gray-800};
}

