@import "variables";

@font-face {
  font-family: '#{$navicon-font-family}';
  src:  url('#{$navicon-font-path}/#{$navicon-font-family}.eot?6qcxxf');
  src:  url('#{$navicon-font-path}/#{$navicon-font-family}.eot?6qcxxf#iefix') format('embedded-opentype'),
    url('#{$navicon-font-path}/#{$navicon-font-family}.ttf?6qcxxf') format('truetype'),
    url('#{$navicon-font-path}/#{$navicon-font-family}.woff?6qcxxf') format('woff'),
    url('#{$navicon-font-path}/#{$navicon-font-family}.svg?6qcxxf##{$navicon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="navicon-"]::before, [class*=" navicon-"]::before {
  font-family: '#{$navicon-font-family}' !important;
  // speak: never;
  // font-style: normal;
  // font-weight: normal;
  // font-variant: normal;
  // text-transform: none;
  // line-height: 1;

  /* Better Font Rendering =========== */
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
.nav-sidebar {
  display: inline-block;
  min-width: 32px;
}

.nav-sidebar::before{
  font-size: 1.3rem;
  margin-right: 15px;
}

.navicon-Asset-Management {
  &:before {
    content: $navicon-Asset-Management;     
    color: #fff;
  }
}
.navicon-Audit--External {
  &:before {
    content: $navicon-Audit--External;     
    color: #fff;
  }
}
.navicon-Audit--Internal {
  &:before {
    content: $navicon-Audit--Internal;     
    color: #fff;
  }
}
.navicon-Budgeting-and-Forecasting {
  &:before {
    content: $navicon-Budgeting-and-Forecasting;     
    color: #fff;
  }
}
.navicon-CRM {
  &:before {
    content: $navicon-CRM;     
    color: #fff;
  }
}
.navicon-Dashboard {
  &:before {
    content: $navicon-Dashboard;     
    color: #fff;
  }
}
.navicon-Document-Management {
  &:before {
    content: $navicon-Document-Management;     
    color: #fff;
  }
}
.navicon-Facilities {
  &:before {
    content: $navicon-Facilities;     
    color: #fff;
  }
}
.navicon-Favourites {
  &:before {
    content: $navicon-Favourites;     
    color: #fff;
  }
}
.navicon-HR {
  &:before {
    content: $navicon-HR;     
    color: #fff;
  }
}
.navicon-Inventory {
  &:before {
    content: $navicon-Inventory;     
    color: #fff;
  }
}
.navicon-IT {
  &:before {
    content: $navicon-IT;     
    color: #fff;
  }
}
.navicon-Legal {
  &:before {
    content: $navicon-Legal;     
    color: #fff;
  }
}
.navicon-Management-Accounting {
  &:before {
    content: $navicon-Management-Accounting;     
    color: #fff;
  }
}
.navicon-Miscellaneous {
  &:before {
    content: $navicon-Miscellaneous;     
    color: #fff;
  }
}
.navicon-MTD {
  &:before {
    content: $navicon-MTD;     
    color: #fff;
  }
}
.navicon-Operations {
  &:before {
    content: $navicon-Operations;     
    color: #fff;
  }
}
.navicon-Period-End {
  &:before {
    content: $navicon-Period-End;     
    color: #fff;
  }
}
.navicon-Portals {
  &:before {
    content: $navicon-Portals;     
    color: #fff;
  }
}
.navicon-Professional-Services {
  &:before {
    content: $navicon-Professional-Services;     
    color: #fff;
  }
}
.navicon-Project-Management {
  &:before {
    content: $navicon-Project-Management;     
    color: #fff;
  }
}
.navicon-Purchasing {
  &:before {
    content: $navicon-Purchasing;     
    color: #fff;
  }
}
.navicon-Reconciliation-Management {
  &:before {
    content: $navicon-Reconciliation-Management;     
    color: #fff;
  }
}
.navicon-Reports {
  &:before {
    content: $navicon-Reports;     
    color: #fff;
  }
}
.navicon-Research-and-Development {
  &:before {
    content: $navicon-Research-and-Development;     
    color: #fff;
  }
}
.navicon-Sales {
  &:before {
    content: $navicon-Sales;     
    color: #fff;
  }
}
.navicon-Statutory-Accounting {
  &:before {
    content: $navicon-Statutory-Accounting;     
    color: #fff;
  }
}

