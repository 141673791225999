@import "src/_metronic/_assets/sass/components/variables.bootstrap";

$blue: #004b8a;
$green: #00e4b5;

.bg-sidenav {
  background: $blue;
}

.nav-green {
  &--bg {
    background: $green I !important;
  }
  &--txt {
    color: $green !important;
  }
}

.order-apps {
  position: absolute;
  width: 30px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 40px;
  top: 0;
  bottom: 0;
}

.size-brand {
  font-size: 1.5rem;
}

.nav-container {
  justify-items: center;
}
.favourite-icon {
  margin-top: 7px;
  margin-left: -15px;
}
.line {
  background: grey;
  width: 2px;
  height: 40px;
  position: relative;
  margin-left:-15px;
  opacity: 0.2;
}
.circle {
  top: 19px;
  background: $white;
  border-radius: 50%; // 100%
  width: 5px;
  height: 5px;
  margin-left:-5px;
  position: relative;
}.circle-active {
  top: 19px;
  background: $green;
  border-radius: 50%; // 100%
  width: 5px;
  height: 5px;
  left: 31px;
  position: absolute;
}


.list-search {
  padding: 10px 30px 20px 30px !important;
  .menu-search {
    background: unset !important;
    border: unset !important;
    border-radius: unset !important;
    border-bottom: 1px solid white !important;
    box-shadow: unset !important;
    // padding: 10px !important;
    &:focus {
      box-shadow: unset !important;
    }

    > input {
      background: unset !important;
      border: unset !important;
      padding: 0 10px 0 10px !important;
      color: white;

      &.ant-input::placeholder {
        color: white !important;
      }
    }
    > span {
      color: white;
    }
  }
}

::placeholder,
::-moz-placeholder,
::-webkit-input-placeholder {
  color: white !important;
}

@media (min-width: 992px) {
  .aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
  .aside-menu .menu-nav > .menu-item > .menu-link .menu-text,
  .aside-menu .menu-nav > .menu-item > .menu-link .order-menu,
  .aside-menu .menu-nav > .menu-item > .menu-link .plus-minus-menu {
    display: none;
  }
}

.margin-text {
  margin-left: 18px !important;
}