@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/fa-5-pro-extends.eot?lzzbdh');
  src: url('#{$icomoon-font-path}/fa-5-pro-extends.eot?lzzbdh#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/fa-5-pro-extends.ttf?lzzbdh') format('truetype'),
    url('#{$icomoon-font-path}/fa-5-pro-extends.woff?lzzbdh') format('woff'),
    url('#{$icomoon-font-path}/fa-5-pro-extends.svg?lzzbdh#fa-5-pro-extends') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.fsi:before {
  font-family: '#{$icomoon-font-family}' !important;
  // speak: none;
  // font-style: normal;
  // // font-weight: normal;
  // font-variant: normal;
  // font-feature-settings: normal;
  // // text-transform: none;
  // text-rendering: auto;
  // line-height: 1;
  // display: inline-block;

  // // Better Font Rendering
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

.fa-sequence-number {
  &:before {
    content: $fa-sequence-number; 
  }
}

