// Icon Sizes
// -------------------------

// makes the font 33% larger relative to the icon container

// Q-CUSTOM
// .#{$fa-css-prefix}-lg
.#{$fa-css-prefix}-lg:before {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -.0667em;
}

// Q-CUSTOM
// .#{$fa-css-prefix}-xs
.#{$fa-css-prefix}-xs:before {
  font-size: .75em;
}

// Q-CUSTOM
// .#{$fa-css-prefix}-sm
.#{$fa-css-prefix}-sm:before {
  font-size: .875em;
}

@for $i from 1 through 10 {
  // Q-CUSTOM
  // .#{$fa-css-prefix}-#{$i}x
  .#{$fa-css-prefix}-#{$i}x:before {
    font-size: $i * 1em;
  }
}
