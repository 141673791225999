// 
// html, body {
// 	width: 100%;
// }

// .aside-secondary {
// 	border-right: 1px solid #EBEDF3;
// }

// .aside-minimize .aside-secondary {
// 	border-right-color: transparent;
// }

// DEVS: aside min max
// .no-anime-aside #kt_aside{
// 	transition: none;
// }

// .aside-primary {
// 	background-color: $main-color;
// }

// .aside-fixed .aside {// .aside-secondary-enabled .aside | .aside-minimize .aside
// 	will-change: width;
// }

// .btn.btn-clean .svg-icon svg g [fill] {
// 	fill: #fff;
// }

// #kt_aside_toggle {
// 	box-shadow: 0 0 0 0.2rem rgba(255,255,255,.5);
// }

// ========================================================

/*body {
	// background: #f3f6f9;
	overflow-y: scroll;
}*/

// USE in MTD
// html body.swal2-height-auto {
// 	height: 100% !important;
// }

sup {
  top: -.25rem; // -.2rem
}

.active > .navi-link,
.active > .navi-link:hover {
	background-color: var(--hover-color) !important;// --primary
}
// .active > .navi-link .navi-text,
// .active > .navi-link:hover .navi-text {
// 	color: #fff !important;
// }

.custom-checkbox .custom-control-label::before {
	border-radius: .25rem;
}

// Override metronic i all
i[class*="fa-"] {
	color: inherit;
}

// Custom scroll thumbnail
.ps__thumb-y:focus,
.ps__thumb-x:focus {
  // outline: none;
	outline-color: transparent;
}

#kt_aside_menu .ps__thumb-y {
	background: #fff;
}
#kt_aside_menu .ps__rail-y {
	z-index: 5;
}

// Override metronic dropdown-menu
/*.aside-workspace .dropdown-menu{
  width: 325px;
}*/
// .fsys-react-select-bs > div {
//   border-color: #E4E6EF;
// }
// Bootstrap:

.modal .modal-header .close span[aria-hidden] {
	display: inherit;
}

// NEW
// F-CUSTOM
// #kt_brand,
/*#kt_header {
	// #01A1D6 | #0059A8
	background-color: #00A1D7; // #B0DCF3 | #0059A8 // #00A1D7
}*/

// USE in MTD
// #kt_brand {
// 	height: 55px;
// }

// USE in MTD
#kt_brand, 
#kt_aside, 
#kt_aside_menu, 
.bg-gradient-main {
	background: rgb(3,76,142);
	background: linear-gradient(90deg,#015096 5%,36%,#0094CE 68%,#00A1D6 93%);
}

// USE in MTD
// #kt_wrapper {
// 	transition: padding .2s ease-in-out;
// }

/* .btn.btn-clean:focus:not(.btn-text), 
.btn.btn-clean.focus:not(.btn-text) */
/* .btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled), 
.show .btn.btn-clean.btn-dropdown {
	background-color: rgba(0,0,0,.3);
} */

// USE in MTD
// .aside-minimize .brand .brand-logo img {
// 	display: inline-block;
// }

// USE in MTD
// .aside-minimize .asideSearchApp, 
// // aside-minimize-hoverable | aside-minimize
// .aside-minimize-hoverable:not(.aside-minimize-hover) #kt_brand .brand-toggle, // <- FOR btn toggle in Aside
// body:not(.aside-minimize) .aside-min-logo,
// .aside-minimize .brand .brand-logo img:first-child {
// 	display: none;
// }

#kt_body .aside-menu .menu-nav > .menu-section .menu-text {
	color: #fff;
}

/*.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link {
	background-color: transparent !important;
}*/

// USE in MTD
.aside-menu .menu-nav .active, 
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link,
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading,
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active) > .menu-link.active {
	// background-color: #01A1D6 !important; // $hover-color
	background-color: rgba(255,255,255, .2) !important;
}

// USE in MTD
// #kt_body .aside-menu .menu-nav > .menu-section .menu-text, 
// .aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon,  
// .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon, 
.aside-menu .menu-nav > .menu-section .menu-icon,
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-arrow,
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-arrow,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-arrow,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow,
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon {
	color: #fff !important; // $primary | #555
}

// USE in MTD
// .aside-menu .menu-nav > .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span, 
// .aside-menu .menu-nav > .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span,

// .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-bullet.menu-bullet-dot > span,
// .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-bullet.menu-bullet-dot > span,
// .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
// .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span,
// .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-bullet.menu-bullet-dot > span,
// .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-bullet.menu-bullet-dot > span {
// 	background-color: #fff !important;
// }

// USE in MTD
// .menu-nav > .menu-item + .menu-item {
// 	border-top: 1px solid rgba(255,255,255,.4);
// }

// USE in MTD
// .aside-minimize #kt_aside_toggle .svg-icon svg {
// 	transition: all .15s ease;
// 	transform: rotate(180deg);
// }

// USE in MTD
// #kt_aside {
// 	will-change: width; // DEV OPTION: width | contents
// 	transition: width .2s ease-in-out;
// }

// MOVE / CHANGE IN JS
// #kt_aside_menu{
//     height: calc(100vh - 97px) !important;// 123px | 133px | 107.5px
// }
/*.aside-minimize #kt_aside:not(:hover) #kt_aside_menu {
	overflow: hidden;
}*/
/*.aside-minimize .aside-footer .brand-toggle {
	margin-right: auto;
}*/

// USE in MTD
// @media (min-width: 992px) {
// 	.header { // .header.header-fixed
// 		height: 55px !important;
// 	}

// 	.header-fixed.subheader-fixed .subheader {
// 		top: 55px;
// 	}

// 	.header-fixed .wrapper {
// 		padding-top: 55px;
// 	}
// }

// USE in MTD
// @media (max-width: 991.98px){
// 	.brand-light #kt_header_mobile,
// 	#kt_header .topbar {
// 		background-color: #00A1D7;
// 	}
// }

@media (max-width: 991.98px) {
	.brand-dark #kt_header_mobile { // .header-mobile
		background-color: #0079BD;
	}
	.brand-dark .header-mobile .burger-icon span,
	.brand-dark .header-mobile .burger-icon span::before,
	.brand-dark .header-mobile .burger-icon span::after {
		background-color: #fff !important;
	}
}

.aside-minimize .findMenus {
  visibility: hidden;
}

// .aside-minimize .plus-minus-menu, 
.aside-minimize .hide-asidemin {
	display: none;
}

.badge.badge-notif {
	padding: 4px 5px;
  // position: absolute !important;
  top: -12px;
  right: -16px;
}

.asideSearchApp > label {
	border-bottom: 1px solid #fff;
}

// dropdown menu with custom header background image
.dd-menu-head-bg .active .text-muted {
	color: #fff !important;
}

// Notification Dropdown Menu
.fs-notif-list > a.active > .navi-link:not(:hover) {
	background-color: transparent !important;
}

.notifItem.unviewed, // :not(.active)
.navi-item.unread:not(.active) > .navi-link,
.navi-item.unread:not(.active) > .navi-link:hover {
  background-color: #e6f3ff !important;
}

/*
.icon-app {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 38px;
	// background-color: #cee6fd;
	color: #fff; // rgba(38, 143, 255, 0.91)
	// border: 3px solid rgba(38,143,255,.5);
	border: 4px solid transparent;
}

// Primary
.icon-app.primary {
	// box-shadow: 0 0 0 .2rem #0073B9; // #00A1D6
	border-color: #0073B9;
	background-color: #1991DB; // #55BFE2
}

// Secondary
// Dark : #0073B9
// Light: #1991DB

// Success
.icon-app.success {
	// box-shadow: 0 0 0 .2rem #07BF80;
	border-color: #07BF80;
	background-color: #15E29C;
}
// Danger
.icon-app.danger {
	// box-shadow: 0 0 0 .2rem #DE1C22; // rgba(38,143,255,.5)
	border-color: #DE1C22;
	background-color: #EE6161;
}
// Warning
.icon-app.warning {
	// box-shadow: 0 0 0 .2rem #FFBC00; // rgba(38,143,255,.5)
	border-color: #FFBC00;
	background-color: #F9CF5B;
}
// Info
.icon-app.info {
	// box-shadow: 0 0 0 .2rem#008197; // rgba(38,143,255,.5)
	border-color: #008197;
	background-color: #1EB2C9;
} */

// Pages
// .ava-upload .ant-upload.ant-upload-select-picture-card
.ant-upload.ant-upload-drag.ava-upload {
	display: flex;
	width: 100%;
	height: 174px; // 174px
	margin-right: 0;
}

.user-card .ant-upload.ant-upload-drag.ava-upload {
	height: 130px; // 120px
}

// .ava-upload .ant-upload.ant-upload-select-picture-card > .ant-upload
.ava-upload.ant-upload.ant-upload-drag .ant-upload-btn {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0;
	position: relative;
}

.ava-uploadtool {
	display: none;
}

.ava-upload:hover .ava-uploadtool {
	display: flex;
}
// END Pages

.fs-tabs .nav {
	flex: none;
}

.fs-tabs .tab-content {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	flex: auto;

	border: 1px solid #EBEDF3;
	border-top: 0;
	border-bottom-left-radius: 0.42rem;
	border-bottom-right-radius: 0.42rem;
}

.fs-tabs.no-min-h .tab-content {
	min-height: auto;
}

// .fs-tab-light
// kanban:
.react-kanban-card-skeleton,
.react-kanban-card,
.react-kanban-card-adder-form {
	max-width: 300px;
	min-width: 300px;
}

.fs-card-kanban .react-kanban-card__title > span {
	display: block;
	width: 100%;
}

.fs-card-kanban .react-kanban-board > div {
	padding: 0 1rem;
}

.fs-card-kanban .react-kanban-column {
	border-radius: .75rem;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
// END kanban

// My Profile - preferences
.ant-select-selection-item .option-icon {
	// line-height: 38px;
	height: 100%;
}
.ant-select-selection-item .option-icon .lh-1-5 {
	font-size: 1rem;
}
.ant-select-selection-item .option-icon .small,
.ant-select-open .option-icon .w-auto {
	display: none;
}

// Native Html style:
// .n-search[type='search']::-webkit-search-cancel-button {
// 	-webkit-appearance: auto;
// }

// landing page:
@media (min-width:768px) {
	// .landing-page .card > img
	// .landing-page > img
	.img-landing-page {
		margin-top: -35px;
		margin-left: 25px;
	}
}

// react-select custom component:
// is-focused
.fs-r-select__control--menu-is-open .valueEl {
	display: none !important;
}

.fs-r-select__control--menu-is-open .fs-r-select__value-container--has-value .fs-r-select__input {
	padding: 2px 8px;
}

.aside-minimize #kt_aside_toggle > span,
.aside-minimize-hover #kt_aside_toggle > span {
  display: inline-block;
  transform: rotate(180deg);
}

