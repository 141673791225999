// Base Class Definition
// -------------------------
// .#{$fa-css-prefix},
// .fas,
// .far,
// .fal,
// .fad,
// .fab

.#{$fa-css-prefix}:before,// Q-CUSTOM
.fas:before,
.far:before,
.fal:before,
.fad:before,
.fab:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;

  speak: none;// OPTION
}

%fa-icon {
  @include fa-icon;
}
