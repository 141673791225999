/* FOR Smartphone Portrait Only */
@media screen and (max-width:480px){
  // antd DatePicker with time
  .ant-picker-datetime-panel {
    flex-direction: column;
    align-items: center;
    background-color: #eee;
  }
  .ant-picker-datetime-panel .ant-picker-date-panel {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-picker-date-panel, 
	.ant-picker-time-panel {
    background-color: #fff;
  }
  .ant-picker-time-panel .ant-picker-content {
    height: 85px;
  }
  // END antd DatePicker with time

  
}
/* END FOR Smartphone Potrait Only */