// CSS Toolip
.tip {
  position: relative;
}

// :not([disabled]):not(.disabled) | [aria-label]
// .tip[aria-label]::after 
.tip[aria-label]:not([disabled])::after {
  filter: alpha(opactiy=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  font-size: 12px;
  background-color: #222;
  border-radius: 4px;
  color: #fff;
  box-shadow: 0 0 9px rgba(0,0,0,.1);// 0 0 4px rgba(255,255,255,.4)
  content: attr(aria-label);
  padding: .5em 1em;// OPTION set equal with element = .1em 1em 
  position: absolute;
  white-space: nowrap;
  z-index: 10;
  // height: 100%;// OPTION set equal with element
}

.tip.tip-white::after {
  background-color:#fff;
  color:#222;
}

.tip.tipT::after {
  bottom: 100%;
  left: 50%;
  margin-bottom: 4px;
  transform: translate(-50%, 10px);
  transform-origin: top;
}

.tip.tipB::after,
.tip.tipBL::after,
.tip.tipBR::after {
  left: 50%;
  margin-top: 4px;
  top: 100%;
  transform: translate(-50%, -10px);
}

.tip.tipR::after {
  left: 100%;
  margin-left: 4px;
  top: 50%;
  transform: translate(-10px, -50%);
}

.tip.tipL::after {
  right: 100%;
  margin-right: 4px;
  top: 50%;
  transform: translate(10px, -50%);
}

.tip.tipT:hover::after{
  transform: translate(-50%, 0);
}

.tip.tipB:hover::after {
  transform: translate(-50%, 0);
}

.tip.tipR:hover::after {
  transform: translate(0, -50%);
}

.tip.tipL:hover::after {
  transform: translate(0, -50%);
}

.tip.tipTL::after,
.tip.tipTR::after {
  bottom: 100%;
  left: 0;
  margin-bottom: 4px;
  transform: translate(0, 10px);
}

.tip.tipBL::after {
	left: 0;
  transform: translate(0, -10px);
}

.tip.tipBR::after {
	transform: translate(0, -10px);
}

.tip.tipTL:hover::after,
.tip.tipTR:hover::after,
.tip.tipBL:hover::after,
.tip.tipBR:hover::after {
  transform: translate(0,0);
}

.tip.tipTR::after,
.tip.tipBR::after {
  left: auto;
  right: 0;
}

// .tip:hover::after 
.tip[aria-label]:not([disabled]):hover::after {
  filter: alpha(opactiy=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}

.show > .tip[aria-label]:not([disabled])::after, 
.show > .tip[aria-label]:not([disabled]):hover::after {
  transition: none;
  opacity: 0;
  transform: none;
}

.tip.tpre::after {
  white-space: pre !important;
  text-align: left;
}

// .tip.tipR.tipInset:hover:after {
//   transform: translate(-110%, -50%);
// }

