﻿// === Img ===
// FOR img wrapper with div & ::after (to prevent contextmenu save as image)
.img-frame,
.ava { // [aria-label]
  position: relative;
}

.img-frame::before {
  content: "";
  position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  inset: 0;
  z-index: 1;
}

.img-frame,// OPTION
.img-frame img {
  user-select: none;
}

.img-frame img + * {
  position: relative;
  z-index: 2;
}

