/* FOR Smartphone Landscape & Portrait */
@media (max-width:767px) {
	// html,
	// body,
	// #root {
	// 	// height: auto; //  !important
	// 	// OPTION: FOR "bounce" effects or refreshes
	// 	// https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
	// 	overscroll-behavior: none; // contain
	// }

	.lockScroll{
		overflow: hidden !important;
	}
	
	// Landing Page:
	// .landing-page .hr-v{
	// 	width: 100%;
	// 	margin: .5rem 0;
	// }

	// .landing-page .card > img
	// .landing-page > img 
	.img-landing-page {
		margin: 1.5rem 0 0 1.5rem;
	}

	// .landing-page .hr-v::before, 
	// .landing-page .hr-v::after {
	// 	width: auto;
	// 	background-color: transparent;
	// 	border-top: 2px solid #dee2e6;
	// 	margin: 0;
	// }

	// .landing-page .hr-v::before {
	// 	margin-right: 9px;
	// }
	// .landing-page .hr-v::after {
	// 	margin-left: 9px;
	// }

	// Helpers:
	// .ws-pre-s-lp {
	// 	white-space: pre;
	// }

	// Sizing:
	// .w-s-lp-75 {
	// 	width: 
	// }

	// Modal bootstrap
	.modal-full-sm .modal-dialog {
		margin: 0;
		height: 100%;
	}

	.modal-full-sm .modal-content {
		height: 100%;
		border-radius: 0;
	}

	// antd:
	// <Table />
	// .ant-table-wrapper
	.ant-table {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		// OPTION: FOR "bounce" effects or refreshes
		// https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
		// overscroll-behavior: none; // contain
	}
	
	// Upload = <FileUpload />
	.fs-file-upload > span {
		display: block;// inline-block
	}
	.fs-file-upload .fs-antd-upload {
		height: 100%;
		margin: 0;
	}
	.fs-file-upload .ant-upload-list {
		width: auto;
		overflow-y: unset;
		display: block;// flex
		min-height: auto;
		// flex-direction: column;
		// min-height: 60vh;
		// max-height: calc(100vh - 15rem);
	}
	.fs-file-upload .ant-upload-list:empty:after {
		line-height: 9rem;
	}
}
/* END Smartphone Landscape & Portrait */