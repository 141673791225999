.chat-wrapper {
    position: fixed;
    bottom: 72px;
    right: 48px;
    height: min(75vh, 640px);
    width: min(75vw, 560px);
    z-index: 9999999;
}

.chat-main-container {
    border-radius: 10px;
    height: min(75vh, 640px);
    width: min(75vw, 560px);

    :global(.cs-message__content-wrapper) {
        max-width: 100%;
    }
}

.chat-button {
    position: fixed !important;
    bottom: 72px;
    right: 48px;
    width: 56px;
    height: 56px;
    z-index: 9999999;
}