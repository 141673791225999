﻿// FormEditor
$ASIDE_WIDTH: 225px !default;
// $ASIDE_WIDTH_DEVICE_1920: 247px !default;
// $ASIDE_RIGHT_WIDTH: 300px !default;
// ============================================================
.disableButtonUpload, 
.disableButtonUpload:focus, 
.disableButtonUpload:hover, 
.disableButtonUpload:active {
  color: #3699FF !important;
  background-color: transparent !important;
  border-color: #86c2ff !important;
  box-shadow: unset !important;
}

.disableImage {
  background-color: $light !important;
}

.fe-aside {
  flex-basis: $ASIDE_WIDTH;
  max-width: $ASIDE_WIDTH;
}

.col-drop {
  flex-basis: calc(100% - #{$ASIDE_WIDTH});
  max-width: calc(100% - #{$ASIDE_WIDTH});
}

// .fe-item.show > .form-group
.fe-item.show > .cpoin {
  position: relative;
  z-index: 1022; // 1022 | 1023
  padding: 0 0.5rem 0.5rem; // .5rem
  background-color: #eee;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.3);
}

.fe-item.show > .dropdown-menu {
  // width: calc(100vw - 279.32px);
  z-index: 1022;
  border: 0;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}

.tab-content-fieldset:not(.tabContentFieldSetupModal) {
  min-height: 21.5rem; // 20rem
  max-height: 50vh;
}

.fe-item-tab {
  border-bottom-left-radius: 0.25rem;
}

.fe-item .list-group-item:not(:hover):not(:active):not(.active) {
  background-color: transparent; // #eee
}

.fe-item-tab .active:after {
  content: "";
  position: absolute;
  top: 0;
  right: -1px; // 0
  width: 0;
  height: 0;
  border-style: solid;
  // border-width: 24.5px 15px 24.5px 0;
  border-width: 20px 15px 20px 0;
  border-color: transparent #fff transparent transparent;
  pointer-events: none;
}

// Material input style
.material-form {
  padding-right: 0;
  padding-left: 0;
  background-color: transparent !important;
  border-width: 0 0 1.5px; // 2px
  border-radius: 0;
  box-shadow: none !important;
}

.form-typo {
  border-bottom: 1.5px dashed transparent;
}

.form-typo:not(:focus):hover {
  border-bottom-color: #ced4da;
}
// END FormEditor

// TableForm
.table-flex {
  display: inline-block;
  border-spacing: 0;
}

.table-flex .th,
.table-flex .td {
  margin: 0;
  height: 40px;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.table-flex--auto .td {
  height: auto;
  min-height: 40px;
}

.table-flex .tr.tfoot td, 
.table-flex .tr:last-child .td {
  border-bottom: 0;
}

// z-index conflig:
.table-flex .th:not(.tbFlexAction):not(:focus-within):hover {
  z-index: 0;
}
.table-flex > [role="row"]:hover > .tbFlexAction:nth-child(1):not(:hover), 
.table-flex > [role="row"]:hover > .fs-dataset-key:not(:hover) {
  z-index: 2;
}

// .table-style .resizerTbstyle
.resizerTbstyle {
  width: 9px;
  right: -4.5px;
  border: 1.5px dashed transparent;
  user-select: none;
}

// .table-style .resizerTbstyle:hover
.resizerTbstyle:hover {
  background-color: rgba(38, 143, 255, 0.2);
  // z-index: 6;
}

.resizerTbstyle:active, 
// .table-style .resizerTbstyle:active, 
.table-style .resizerTbstyle.active {
  background-color: rgba(38, 143, 255, 0.4);
}

.tr-hover:hover .tbFlexAction .ant-checkbox-wrapper > span:nth-child(2), 
.tr-hover .tbFlexAction .ant-checkbox-wrapper-checked > span:nth-child(2), 
.tr-hover:not(:hover) .tbFlexAction label:not(.ant-checkbox-wrapper-checked) > span.ant-checkbox {
  display: none;
}

.fs-fe-tabledit .td .ant-select .ant-select-selection-search-input, 
.fs-fe-tabledit .tr .ant-input-number-input-wrap, 
.fs-fe-tabledit .tr .ant-input-number-input-wrap input {
  height: 100% !important;
}

.table-flex .shadow-danger:focus-within .invalid-tooltip, // OPTION
.table-flex .shadow-danger:focus .invalid-tooltip {
  display: block;
}

.fs-fe-tabledit .td .ant-select .ant-select-selector {
  display: flex;
  align-items: center;
  height: 100% !important;
  border: 0 !important;
  border-radius: 0 !important;
}

// .table-flex .td[role="cell"]:not(.tbFlexAction):not(.active) {
//   padding: 0 1rem;
//   cursor: cell;
// }
// .table-flex .td:not(.active):focus {
//   box-shadow: inset 0 0 0 .1rem rgba(0,123,255,.5) !important;
// }

.table-flex .t-textarea:not(.active) .text-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 17.6px;
  white-space: pre;
  width: 100%;
}

.table-flex .t-number, 
.table-flex .t-currency {
  // text-align: right;
  justify-content: flex-end;
}

.table-flex .t-number input, 
.table-flex .t-currency  input {
  font-size: 1rem;
  color: #3F4254;
}

.table-flex .t-lookup, 
.table-flex .t-remote-lookup, 
.table-flex .t-textarea.active, 
.table-flex .t-checkbox, 
.table-flex .t-checklist, 
.table-flex .t-dropdown,
.table-flex .t-signature, 
.table-flex .t-date, 
.table-flex .t-datetime,
.table-flex .t-time {
  padding: .1rem !important;
  // cursor: auto;
}

.table-flex .t-image, 
.table-flex .t-file,
.table-flex .t-multi-file {
  padding: .25rem !important;
}

// .table-flex  .td .ant-picker-focused, 
// .table-flex .t-text:focus-within, 
.table-flex .t-lookup:focus-within, 
.table-flex .t-remote-lookup:focus-within, 
.table-flex .t-checklist:focus-within, 
.table-flex .t-signature:focus-within, 
.table-flex .t-slider:focus-within, 
.table-flex .t-rating:focus-within, 
.table-flex .t-date:focus-within, 
.table-flex .t-datetime:focus-within {
  box-shadow: inset 0 0 0 .1rem rgba(0,123,255,.5) !important;
}

.table-flex .fs-react-select {
  width: 100%;
}
.table-flex .fs-r-select__control {
  border-radius: 0;
  border: 0 !important;
  box-shadow: none;
  width: 100%;
}

.table-flex .fs-r-select__indicator {
  padding-left: 2px;
  padding-right: 2px;
}

// row.isSelected === true
.bg-hover-color > .form-control, 
.bg-hover-color > .ant-input-number, 
.bg-hover-color .ant-picker, 
.bg-hover-color .ant-picker-input > input {
  transition: none;
  background-color: transparent;
}

// OPTION ???
// .table-flex [role="cell"]:not(.shadow-danger) {
//   box-shadow: none !important;
// }
// END OPTION ???

// TableDataset
.tbFlexAction.fs-dataset-key {
  left: 38px;
  overflow: visible !important;
}
// .tbFlexAction.fs-dataset-key:hover {
//   // overflow: visible !important;
//   z-index: 1;
// }
// .tbFlexAction.fs-dataset-key:not(:hover) {
//   z-index: 2;
// }

.tr.tr-drop:after {
  content: "";
  background-color: rgba(0,0,0, .4); // 0.4 | 0.05
  position: absolute;
  inset: 0;
  z-index: 2;
}

// .tb-dataset .td:not(.t-textarea) {
//   padding: 0 !important;
// }

// .tb-dataset .tbody .td:not(.tbFlexAction)
.tb-dataset .t-text, 
.tb-dataset .t-number {
  padding: .1rem !important;
}

.tb-dataset .t-switch {
  // box-shadow: none !important;
  padding: .15rem .75rem;
}

// .tb-dataset .tbody .td:not(.tbFlexAction):focus-within
// .focus-shadow, 
.tb-dataset .t-text:focus-within, 
.tb-dataset .t-number:focus-within {
  box-shadow: inset 0 0 0 .1rem rgba(0,123,255,.5) !important;
}

// .tb-dataset .t-textarea, 
// .tb-dataset .t-rating, 
// .tb-dataset .t-slider, 
// .tb-dataset .t-switch {
//   padding: 0 1rem;
//   align-items: center;
// }

.tb-dataset .t-date:hover .ant-picker-clear, 
.tb-dataset .shadow-danger:focus-within, 
.tb-dataset .shadow-danger:focus {
  z-index: 2;
}

.tb-dataset .t-datetime:not(:focus):after, 
.tb-dataset .t-date:not(:focus):after {
  content: "";
  position: absolute;
  inset: 0;
  cursor: text;
}

// DEVS: Hide error info in cell
// input:not([value=""]) + .cell-err {
//   display: none !important;
// }
// END TableDataset

// Datatable - Component by Heri
.fs-dt-entry .react-bootstrap-table {
  overflow-x: unset;
}

// .fs-dt-entry .table thead th {
//   padding-top: .5rem;
//   padding-bottom: .5rem;
// }

.fs-dt-entry .selection-cell > input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
}

/*
.fs-dt-entry tfoot th:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
  // background: #fff !important;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.fs-dt-entry thead tr .selection-cell-header,
.fs-dt-entry tbody tr .selection-cell,
.fs-dt-entry tfoot tr th:first-child {
  position: sticky;
  left: 0; // -1px | 0
  z-index: 2;
  // background-color: #fff;
  // box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  // border-left: 0 !important;
}

.fs-dt-entry thead tr th:nth-child(2),
.fs-dt-entry tbody tr td:nth-child(2),
.fs-dt-entry tfoot tr th:nth-child(2) {
  position: sticky;
  left: 32.5px; // 33px
  z-index: 1;
  // background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.fs-dt-entry thead tr .selection-cell-header,
.fs-dt-entry tbody tr .selection-cell, 
.fs-dt-entry thead tr th:nth-child(2),
.fs-dt-entry tbody tr td:nth-child(2) {
  background-color: #fff;
}
// END Datatable
*/

// .formHeadDropdownMenu {
//   // width: calc(100vw - 279.32px);
//   left: -20px !important; // 20px
// }
